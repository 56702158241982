export default class PostAffiliatePro {
  static trackSubscription (subscription) {
    const PostAffTracker = window.PostAffTracker

    PostAffTracker.setAccountId('default1')
    var sale = PostAffTracker.createSale()
    sale.setTotalCost('69')
    sale.setOrderID('sub-' + subscription.subscription_id)
    sale.setProductID(subscription.braintree_plan)

    PostAffTracker.register()
  }
}
