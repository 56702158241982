<template>
    <transition name="fade">
        <div tabindex="0"
             class="overlay is-active is-full-page"
             v-show="isActive">
            <div class="background"></div>
            <div class="citron-logo">
                <object width="150"  height="150" type="image/svg+xml" :data="require('../assets/cog-spin-slow.svg')"></object>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'page-loading',
    props: {
        active: Boolean,
        width: Number,
        height: Number,
    },
    data() {
        return {
            isActive: this.active
        }
    },
    watch: {
        active(value) {
            this.isActive = value
        }
    },
}
</script>

<style scoped>
    .overlay {
        bottom: 0;
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
        align-items: center;
        display: flex;
        justify-content: center;
        overflow: hidden;
        z-index: 9999;
    }
    .overlay .background {
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        background: #fff;
        opacity: 0.5;
    }
    .overlay .citron-logo {
        position: relative;
    }
</style>
